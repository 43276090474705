<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-tabs
        dense
        indicator-color="orange"
        align="center"
        inline-label
        active-color="white"
        class="bg-blue-12 text-blue-11 shadow-2"
        v-model="tab"
      >
        <q-tab label="Penghasilan" name="penghasilan"></q-tab>
        <q-tab label="Jabatan" name="jabatan"></q-tab>
      </q-tabs>
      <q-tab-panels v-model="tab">
        <q-tab-panel name="penghasilan" class="q-pa-none">
          <q-card-section>
            <p class="text-center text-h6 text-grey-8">SMP PA</p>
            <q-markup-table
              dense
              flat
              bordered
              wrap-cells
              separator="cell"
              class="stickyTable"
              style="height:200px"
              v-if="stat_gaji[0]"
            >
              <thead>
                <tr>
                  <th>Penghasilan Ortu</th>
                  <th>Jumlah Siswa</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val, i) in stat_gaji[0].data" :key="i">
                  <td>{{ val.label }}</td>
                  <td class="text-center">{{ val.banyak }}</td>
                </tr>
              </tbody>
            </q-markup-table>
          </q-card-section>
          <q-separator></q-separator>
          <q-card-section>
            <p class="text-center text-h6 text-grey-8">SMP PI</p>
            <q-markup-table
              dense
              flat
              bordered
              wrap-cells
              separator="cell"
              class="stickyTable"
              style="height:200px"
              v-if="stat_gaji[1]"
            >
              <thead>
                <tr>
                  <th>Penghasilan Ortu</th>
                  <th>Jumlah Siswa</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val, i) in stat_gaji[1].data" :key="i">
                  <td>{{ val.label }}</td>
                  <td class="text-center">{{ val.banyak }}</td>
                </tr>
              </tbody>
            </q-markup-table>
          </q-card-section>
          <q-separator></q-separator>
          <q-card-section>
            <p class="text-center text-h6 text-grey-8">MA</p>
            <q-markup-table
              dense
              flat
              bordered
              wrap-cells
              separator="cell"
              class="stickyTable"
              style="height:200px"
              v-if="stat_gaji[2]"
            >
              <thead>
                <tr>
                  <th>Penghasilan Ortu</th>
                  <th>Jumlah Siswa</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val, i) in stat_gaji[2].data" :key="i">
                  <td>{{ val.label }}</td>
                  <td class="text-center">{{ val.banyak }}</td>
                </tr>
              </tbody>
            </q-markup-table>
          </q-card-section>
          <q-separator></q-separator>
        </q-tab-panel>
        <q-tab-panel
          name="jabatan"
          class="q-pa-none column justify-between"
          style="height:100%;"
        >
          <q-card-section class="q-pa-sm">
            <q-btn
              unelevated
              class="full-width q-mb-sm"
              color="primary"
              label="FILTER"
              @click="showFilter = true"
            ></q-btn>
            <q-input
              id="inputText"
              outlined
              label="Cari Nama"
              v-model="searchTerm"
            ></q-input>
          </q-card-section>
          <q-scroll-area class="bg-grey-3" style="height:calc(100vh - 238px);">
            <q-list separator class="bg-grey-4" id="searcharea">
              <q-item
                v-for="(val, i) in filteredJabatan"
                :key="i"
                clickable
                v-ripple
                @click="
                  detailData = val;
                  showDetail = true;
                "
              >
                <q-item-section>
                  <q-item-label class="text-body1"
                    ><span v-html="val.nama"></span
                  ></q-item-label>
                  <q-item-label caption class="text-black"
                    >Nama Siswa : <span v-html="val.nama_siswa"></span
                  ></q-item-label>
                  <q-item-label caption class="text-black"
                    >Jabatan : <span v-html="val.jabatan"></span
                  ></q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-scroll-area>
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
    <q-dialog v-model="showDetail">
      <q-card style="width:300px">
        <q-card-section class="row items-center justify-between">
          <a class="text-h6">Detail</a>
          <q-btn flat icon="close" v-close-popup></q-btn>
        </q-card-section>
        <div class="q-px-md">
          <a class="text-body1 text-bold">Orang Tua :</a>
        </div>
        <q-markup-table flat wrap-cells separator="horizontal">
          <tr>
            <td>Nama</td>
            <td>:</td>
            <td v-if="!detailData.nama">-</td>
            <td v-else>{{ detailData.nama }}</td>
          </tr>
          <tr>
            <td>Tingkat Jabatan</td>
            <td>:</td>
            <td v-if="!detailData.tingkat_jabatan">-</td>
            <td v-else>{{ detailData.tingkat_jabatan }}</td>
          </tr>
          <tr>
            <td>Jabatan</td>
            <td>:</td>
            <td v-if="!detailData.jabatan">-</td>
            <td v-else>{{ detailData.jabatan }}</td>
          </tr>
          <tr>
            <td>Kontak</td>
            <td>:</td>
            <td v-if="!detailData.hp">-</td>
            <td v-else>{{ detailData.hp }}</td>
          </tr>
        </q-markup-table>

        <div class="q-my-md">
          <q-separator inset=""></q-separator>
        </div>

        <div class="q-px-md">
          <a class="text-body1 text-bold">Siswa :</a>
        </div>
        <q-markup-table flat wrap-cells separator="horizontal" class="q-mb-md">
          <tr>
            <td>Nama</td>
            <td>:</td>
            <td v-if="!detailData.nama_siswa">-</td>
            <td v-else>{{ detailData.nama_siswa }}</td>
          </tr>
          <tr>
            <td>jenjang</td>
            <td>:</td>
            <td v-if="!detailData.jenjang">-</td>
            <td v-else>{{ detailData.jenjang }}</td>
          </tr>
          <tr>
            <td>tingkat</td>
            <td>:</td>
            <td v-if="!detailData.tingkat">-</td>
            <td v-else>{{ detailData.tingkat }}</td>
          </tr>
          <tr>
            <td>Kelas</td>
            <td>:</td>
            <td v-if="!detailData.nama_kelas">-</td>
            <td v-else>{{ detailData.nama_kelas }}</td>
          </tr>
          <tr>
            <td>Asrama</td>
            <td>:</td>
            <td v-if="!detailData.nama_asrama">-</td>
            <td v-else>{{ detailData.nama_asrama }}</td>
          </tr>
          <tr>
            <td>Kamar</td>
            <td>:</td>
            <td v-if="!detailData.nama_kamar">-</td>
            <td v-else>{{ detailData.nama_kamar }}</td>
          </tr>
        </q-markup-table>
      </q-card>
    </q-dialog>
    <q-dialog v-model="showFilter" position="bottom">
      <q-card style="width:300px">
        <q-card-section class="row justify-between q-py-sm">
          <span class="text-h6">Filter :</span>
          <q-btn flat icon="close" v-close-popup></q-btn>
        </q-card-section>
        <q-card-section class="column q-gutter-sm">
          <div class="column q-gutter-xs">
            <label class="text-bold">Jenjang :</label>
            <q-select
              outlined
              v-model="fil.jenjang"
              :options="opt.jenjang"
            ></q-select>
          </div>
          <div class="column q-gutter-xs">
            <label class="text-bold">Tingkat Jabatan :</label>
            <q-select
              outlined
              v-model="fil.tingkat_jabatan"
              :options="opt.tingkat_jabatan"
            ></q-select>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: "jabatan",
      searchTerm: "",
      showFilter: false,
      showDetail: false,
      detailData: {},

      stat_gaji: [],
      format: [
        { label: "Tidak Berpenghasilan", banyak: 0 },
        { label: "< 1jt", banyak: 0 },
        { label: "1jt - 2jt", banyak: 0 },
        { label: "2jt - 5jt", banyak: 0 },
        { label: "5jt - 20jt", banyak: 0 },
        { label: "> 20jt", banyak: 0 },
      ],

      ortu: [],
      fil: {
        jenjang: "SEMUA JENJANG",
        tingkat_jabatan: "SEMUA TINGKAT",
      },

      showOrtu: [],
      opt: {
        jenjang: [],
        tingkat_jabatan: [
          "SEMUA TINGKAT",
          "Legislatif",
          "Eksekutif",
          "Kepala Daerah",
          "Birokrat",
          "Eselon 1",
          "Eselon 2",
          "Eselon 3",
          "Eselon 4",
          "BUMN",
          "Pengusaha",
          "PNS",
          "Lainya",
        ],
      },
    };
  },
  computed: {
    filteredJabatan() {
      let temp = JSON.parse(JSON.stringify(this.ortu));

      if (this.fil.jenjang != "SEMUA JENJANG") {
        temp = temp.filter((val) => {
          return val.jenjang == this.fil.jenjang;
        });
      }

      if (this.fil.tingkat_jabatan != "SEMUA TINGKAT") {
        temp = temp.filter((val) => {
          return val.tingkat_jabatan == this.fil.tingkat_jabatan;
        });
      }

      if (this.searchTerm != "") {
        let all = [];
        let nama = temp.filter((val) => {
          return (
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1
          );
        });
        let siswa = temp.filter((val) => {
          return (
            val.nama_siswa
              .toLowerCase()
              .indexOf(this.searchTerm.toLowerCase()) != -1
          );
        });
        let jabatan = temp.filter((val) => {
          return (
            val.jabatan.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !=
            -1
          );
        });

        all = all.concat(nama);
        all = all.concat(siswa);
        all = all.concat(jabatan);

        let mapidx = all.map((o) => o.id);
        temp = all.filter(({ id }, index) => !mapidx.includes(id, index + 1));

        //marking
        let items = [];
        for (let item of temp) {
          //labeling nama
          let idx = item.nama
            .toLowerCase()
            .indexOf(this.searchTerm.toLowerCase());
          if (idx != -1) {
            let x1 = item.nama.split("");
            x1[idx] = `<mark>${x1[idx]}`;
            x1[this.searchTerm.length + idx - 1] = `${
              x1[this.searchTerm.length + idx - 1]
            }</mark>`;
            item.nama = x1.join("");
          }

          //labeling siswa
          idx = item.nama_siswa
            .toLowerCase()
            .indexOf(this.searchTerm.toLowerCase());
          if (idx != -1) {
            let x1 = item.nama_siswa.split("");
            x1[idx] = `<mark>${x1[idx]}`;
            x1[this.searchTerm.length + idx - 1] = `${
              x1[this.searchTerm.length + idx - 1]
            }</mark>`;
            item.nama_siswa = x1.join("");
          }

          //labeling jabatan
          idx = item.jabatan
            .toLowerCase()
            .indexOf(this.searchTerm.toLowerCase());
          if (idx != -1) {
            let x1 = item.jabatan.split("");
            x1[idx] = `<mark>${x1[idx]}`;
            x1[this.searchTerm.length + idx - 1] = `${
              x1[this.searchTerm.length + idx - 1]
            }</mark>`;
            item.jabatan = x1.join("");
          }
          items.push(item);
        }
        temp = items;
      }

      return temp;
    },
  },
  async mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    await this.getData();
    await this.getJabatan();
    this.opt.jenjang = JSON.parse(JSON.stringify(this.$LIST_JENJANG));
    this.opt.jenjang.unshift("SEMUA JENJANG");
    this.$q.loading.hide();
  },
  methods: {
    trydistinct() {
      const data = [
        { nama: "alghi", bayar: 5000 },
        { nama: "febi", bayar: 6000 },
        { nama: "rilo", bayar: 7000 },
        { nama: "alghi", bayar: 6000 },
        { nama: "febi", bayar: 7000 },
      ];
      const unique = [...new Set(data.map((item) => item))];
      console.log(unique);
    },
    async getData() {
      let resp = await this.$http.get(`/statistik/ortu/getgaji`);

      // filter gaji
      let stat_gaji = [];
      for (let item of resp.data[0]) {
        let row = {
          jenjang: item.jenjang,
          data: JSON.parse(JSON.stringify(this.format)),
        };
        for (let siswa of item.data) {
          if (siswa.kode == 0) {
            row.data[0].banyak++;
          } else if (siswa.kode >= 1 && siswa.kode <= 10) {
            row.data[1].banyak++;
          } else if (siswa.kode >= 11 && siswa.kode <= 20) {
            row.data[2].banyak++;
          } else if (siswa.kode >= 21 && siswa.kode <= 50) {
            row.data[3].banyak++;
          } else if (siswa.kode >= 51 && siswa.kode <= 200) {
            row.data[4].banyak++;
          } else if (siswa.kode > 200) {
            row.data[5].banyak++;
          }
        }
        stat_gaji.push(row);
      }
      this.stat_gaji = stat_gaji;
    },
    async getJabatan() {
      let resp = await this.$http.get(`/statistik/ortu/getjabatan`);
      this.ortu = resp.data;
    },
    getShow() {},
  },
};
</script>
<style scoped>
.highlight {
  background-color: yellow;
}
</style>
